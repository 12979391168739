const licenceContract = {
  section1: {
    title: `Conditions d’utilisation et Contrat de licence Distribution Digitale`,
    description: `Il s’agit d’un contrat de licence NON exclusif qui nous permet de vendre et de monétiser vos enregistrements. Ce contrat complète les Conditions Générales d’Utilisation d’un compte Dropzik.

    Cet accord s’applique uniquement aux titre que vous distribuez dans ce forfait, et nous créerons un nouveau contrat de licence de distribution digitale pour chaque forfait que vous distribuerez.`,
  },
  section2: {
    title: `Définitions`,
    items: [
      {
        title: `Vous`,
        description: `  le titulaire des droits (ou son représentant légal) sur les enregistrements (vous ne devez pas nécessairement être le créateur original des titres).`
      },
      {
        title: `Nous`,
        description: ` Dropzik Digital INC, Canada.`
      },
      {
        title: `Sortie`,
        description: ` ensemble d’enregistrements/de titres tels qu’un single, un EP, un album, un vidéoclip, un livre audio ou similaire.`
      },
      {
        title: `Titre`,
        description: ` une chanson ou une piste audio.`
      },
      {
        title: `Magasin(s)`,
        description: ` fournisseur(s) de services numériques (DSP) comme Spotify, YouTube ou même Shazam.`
      },
      {
        title: `Partenaire`,
        description: `  les partenaires de Dropzik tels que les magasins, les agrégateurs, les encodeurs, etc.`
      },
      {
        title: `Conditions d’utilisation`,
        description: `  ce sont les conditions qui régissent la façon dont vous utilisez votre compte Dropzik et que vous avez accepté lorsque vous avez ouvert votre compte.`
      },
    ]
  },
  section3: {
    title: `Garanties`,
    items: [
      `Vous garantissez que vous avez le droit d’administrer les droits de propriété intellectuelle de(s) l’enregistrement(s) de ce forfait.`,
    ]
  },
  section4: {
    title: `Terme du contrat`,
    items: [
      `Il s’agit d’un contrat à durée indéterminée, ce qui signifie que vous pouvez décider vous-même quand y mettre fin et arrêter la monétisation. Voir la section «Résiliation» pour connaître les modalité de résiliation de la licence.`
    ],
  },
  section5: {
    title: `Exclusivité et cession`,
    items: [
      `Ce contrat peut faire l’objet d’une cession. Il est NON exclusif pour les droits de propriété intellectuelle des titres de ce forfait.`,
      `Cela signifie que vous pouvez avoir des accords directs pour ce forfait avec des magasins individuels, mais cette offre est exclusive en termes de collaboration avec d’autres distributeurs pour cette sortie. Les magasins opéreront un retrait des sorties dupliquées, nous nous réservons donc le droit de retirer ces sorties sans remboursement.`
    ]
  },
  section6: {
    title: `Revenus et commissions`,
    items: [
      `Dès que nous aurons reçu les relevés/revenus des services d’identification de contenu, vos revenus ainsi que vos relevés seront mis à disposition sur votre compte. Si une commission est applicable, nous la déduisons avant de mettre en ligne vos revenus.`,
    ]
  },
  section7: {
    title: `Retraits (Takedowns)`,
    description: `Mettre fin à ce contrat est la même chose que d’émettre une demande de retrait d’une sortie. C’est cependant différent que de demander la clôture de votre compte Dropzik (fermeture de votre compte). L’une ou l’autre des parties peut mettre fin à ce contrat et déclencher à tout moment un retrait automatique de la sortie.  Les résiliations peuvent être faites par écrit (un courrier électronique suffit).
    Pour demander la clôture de votre compte Dropzik (fermer votre compte), voir les Conditions générales d’utilisation.
    Nous nous réservons le droit de facturer des frais de retrait anticipé si une sortie est retirée des magasins au cours des 12 premiers mois. 
    Les retraits dépendent des politiques internes des magasins et peuvent souvent prendre des mois.
    Chaque magasin ou service a ses propres politiques de contenu (qui sont hors de notre contrôle) et peut retirer votre sortie sans raison.`,
  },
  section8: {
    title: `Changements de votre catalogue `,
    description: `Vous pouvez mettre à jour votre sortie à tout moment.  Si votre sortie est déjà en ligne (et donc livrée dans les magasins), des frais peuvent s’appliquer.`,
  },
  section9: {
    title: `Magasins et territoires`,
    description: `Le magasin et les choix de territoires sont déterminés par vous, mais la politique de contenu de chaque magasin ou territoire (par exemple, la Chine) est hors de notre contrôle.  Par conséquent, nous ne pouvons pas garantir qu’un certain magasin acceptera votre sortie ou que votre sortie pourra être vendue, par exemple, en Chine.`,
  },
  section10: {
    title: `Contrôle qualité et contenu douteux`,
    description: `Nous nous réservons le droit de modifier vos données en fonction des meilleures pratiques et des évolutions des formats standards de l’industrie musicale. Par exemple : Beaucoup de magasins rejettent les titres écrits en majuscules, donc nous changerions cela.  De plus, nous nous réservons le droit de refuser votre publication en raison de la qualité du contenu audio ou douteux. Il peut s’agir de la sortie dans son intégralité ou du refus de le livrer à certains magasins.

    Le contenu douteux comprend des éléments tels que des paroles ou des illustrations racistes ou politiquement sensibles, de la pornographie, etc.
    Atteintes aux droits de propriété intellectuelle/Copyright et activité frauduleuse 
    Nous avons des politiques strictes en matière de violation des droits de propriété intellectuelle/ du Copyright et d’activités frauduleuses. 
    De telles violations de ces droits incluent mais ne sont pas limitées à :`,
    items: [
      `Monétisation de pistes pour lesquelles vous n’êtes pas autorisé à monétiser.`,
      `Monétiser des enregistrements avec des samples pour lesquels aucune autorisation n’a été donnée.`,
      `Des données inexactes, en particulier au sujet des titulaires des droits de propriété intellectuelle/Copyright.`,
    ],
  },
  section11: {
    description: `Les activités frauduleuses comprennent, sans toutefois s’y limiter :`,
    items: [
      `Enrichissement frauduleux par augmentation artificielle des écoutes et/ou des téléchargements.`,
      `L’utilisation non autorisée de pistes, noms d’artistes, marques de commerce et autres qui pourraient vous donner un avantage commercial.  `,
      `Tromperie par la création de plusieurs comptes Dropzik.`,
    ],
  },
  section12: {
    description: `Dans le cas où nous aurions conclu que vous auriez enfreint l’une de ces réglementations, nous nous réservons le droit de :`,
    items: [
      `Divulguer vos données personnelles aux autorités compétentes qui s’occupent du litige ou de la situation.`,
      `Geler et/ou fermer votre compte Dropzik.`,
      `Geler le versement de vos revenus, retenir le versement de vos revenus, ou même exiger le remboursement des revenus déjà versés pour les titres litigieux ou l’ensemble de vos titres.`,
      `Retirer des plateformes vos titres offensants ou l’ensemble de vos titres.`,
      `Refuser votre droit à un remboursement.`,
      `Facturer une pénalité pouvant aller jusqu’à 100 CAD par piste impliquée dans la violation des droits de propriété intellectuelle/Copyright  ou par activité frauduleuse.`
    ],
  },
  section13: {
    title: `Droits promotionnels`,
    description: `Vous acceptez de concéder à Dropzik une licence gratuite, les droits d’utilisation de votre nom, de votre image (photos et images), de votre voix, de votre matériel biographique, de votre marque et de votre logo dans le cadre de l’exploitation de votre sortie.`
  }
};

export default licenceContract;