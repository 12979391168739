import React from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import licenceContract from '../config/contents/contrat-de-licence';
import { KeyValueObject } from "../types/shared";

const ConditionsYoutubePage: React.FC<PageProps<any>> = ({ data }) => {

  /**
   * render a list of content
   * @param items lisst of some content
   */
  const renderItems = (items: KeyValueObject<string>[]) => (
    <ul className='list-items'>
      {
        items[0]?.title
          ? items.map(item => (
            <li key={item.title} className="list-item">
              <span className='font-weight-bold'>{item.title}</span>:
              <span>{item.description}</span>
            </li>
          ))
          : items.map(item => (
            <li key={item} className="list-item">{item}</li>
          ))
      }
    </ul>
  );

  return (
    <Layout>
      <SEO title={`Contrat de licence`} />

      <div className="bg-white d-block pt-5">

        <div className="container pt-5">
          <div className="container__row row">
            {
              Object.keys(licenceContract).map(key => (
                <div key={key} className="d-block">
                  <h2 className="title mt-0 mb-3">{licenceContract[key].title}</h2>
                  {
                    licenceContract[key]?.description && (
                      <p>{licenceContract[key].description}</p>
                    )
                  }
                  {
                    licenceContract[key]?.items && renderItems(licenceContract[key].items)
                  }
                </div>
              ))
            }
          </div>
        </div>

      </div>


    </Layout>
  );
}

export const query = graphql`
  query licenceContractQuery {
    pageImage: file(relativePath: { eq: "dropzik-digital-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default ConditionsYoutubePage;
